export const ctaSection = {
    main: {
        title:"Tingkatkan Efisiensi Bisnis Kamu Sekarang!",
        description: "Gabung dengan Ledgerowl, mulai optimalkan produktivitas bisnis.",
        button: {
            text:"Konsultasi Gratis Sekarang",
            icon:'wa'
        }
    },
    mini_card: {
        title_bold:"Profit",
        title_after:"bulan ini",
        desc: "dari minggu lalu"
    }
}