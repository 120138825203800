import Image from 'next/image'
import React, { useState } from 'react'
import gradient1 from "@/assets/contactus/gradient-1.svg"
import gradient2 from "@/assets/contactus/gradient-2.svg"
import gradient3 from "@/assets/contactus/gradient.svg"
import groupGradient from "@/assets/contactus/group-gradient.svg"
import axios from 'axios'
import { contactUs } from '@/helpers/content/id/contactus'
import InputText from '../../Inputs/InputText'
import { montserratBold, montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import Textarea from '../../Inputs/Textarea'
import PhoneNumberInput from '../../Inputs/PhoneNumberInput'
import Select from 'react-select'
import { dataIndustry } from '@/libs/listindustry'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Button from '@/components/Button/Button'
import { trackGAEvent } from '@/libs/ga4analytics'
import { useRouter } from 'next/router'
import { sendGAEvent, sendGTMEvent } from '@next/third-parties/google'

export default function ContactForm({contactRef}) {
  let newSectionContent = contactUs.find((v) => v.type == "section");
  let newLabelForm = contactUs.find((v) => v.type == "label-form")
  const [sectionContent, setSectionContent] = useState(newSectionContent.list) 
  const [selectedIndustry, setselectedIndustry] = useState(null);
  const [labelForm, setlabelForm] = useState(newLabelForm.list)
  const [isOther, setisOther] = useState(false);
  const [isLoading, setisLoading] = useState(false)
  const [isSuccess, setisSuccess] = useState(false)
  let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const router = useRouter()
  const [form, setform] = useState({
    name: null,
    email: null,
    phone: null,
    message: null,
    industry: null,
  });
  const [isError, setisError] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
    industry: false,
  });

  const [messageError, setmessageError] = useState({
    name: null,
    email: null,
    phone: null,
    message: null,
    industry: null,
  });

  function selectIndustry(e) {
    setselectedIndustry(e);
    if (e.value == "other") {
      setisOther(true);
      setform({ ...form, ["industry"]: null });
    } else if (e.value == 0) {
      setisOther(false);
      setform({ ...form, ["industry"]: null });
    } else {
      setisOther(false);
      setform({ ...form, ["industry"]: e.value });
      setisError({ ...isError, ["industry"]: false });
      setmessageError({ ...messageError, ["industry"]: null });
    }
  }

  function onChange(e) {
    if (e.target.name == "email") {
      let emailval = e.target.value
      if (!emailRegex.test(emailval) && emailval) {
        setisError({...isError, email:true})
        setmessageError({...messageError,email:"Please input format email"})
      }else{
        setisError({...isError, email:false})
        setmessageError({...messageError,email:null})
      }
    }else{
      setisError({ ...isError, [e.target.name]: false });
      setmessageError({ ...isError, [e.target.name]: null });
    }
    setform({ ...form, [e.target.name]: e.target.value });
  }

  function phoneChange(e) {
    setform({ ...form, ["phone"]: e });
    setisError({ ...isError, ["phone"]: false });
    setmessageError({ ...messageError, ["phone"]: null });
  }

  function clearFormIndustry() {
    setform({ ...form, ["industry"]: null });
    setisOther(false);
    setselectedIndustry(null);
  }
  
  
  async function handleSubmit(){
    setisLoading(true)
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    console.log(regexEmail.test(form.email))
    const actionGA = JSON.stringify({
      page: router.pathname == "/" ? "home" : router.pathname,
      name: form.name,
      industry: form.industry,
      message: form.message,
      phone: form.phone,
      email: form.email
    })
    // sendGTMEvent({
    //   event: "form_submitted",
    // })
    // sendGAEvent({
    //   event: "form_submitted",
    //   category: "ContactForm",
    //   action: "Submit",
    //   label: actionGA,
    //   value: 1
    // })
    if (!regexEmail) {
      setmessageError({...messageError,["email"] : "Please input format email"})
    }else{
      axios.post("/api/email",{...form}).then((res) => {
        setisLoading(false)
        setform({
          name: "",
          email: "",
          phone: "+62",
          message: "",
          industry: "",
        })
        setselectedIndustry(null);
        setisSuccess(true)
        setTimeout(() => {
          setisSuccess(false)
        }, 5000);
      }).catch((err) => {
        setisLoading(false)
      }) 
    }
   
  }

  return (
    <div ref={contactRef} className='flex overflow-hidden px-4 lg:px-8 xl:mx-auto bg-blue-primary-600 lg:flex-row flex-col h-full w-full relative'>
        <div className='absolute bg-[#FF9FA0] w-[500px] right-[60%] flex justify-center h-[500px] rounded-full opacity-100 blur-[250px] skew-y-[20deg]'>
        </div>
        <div className='absolute bg-[#7BD2FE] right-[30%] -top-[30%] blur-[220px] flex justify-center w-[500px] h-[500px] rounded-full opacity-100 skew-y-[20deg]'>
        </div>
        <div className='absolute bg-[#7BD2FE] blur-[220px] right-0 flex justify-center w-[500px] h-[500px] rounded-full opacity-100 skew-y-[20deg]'>
        </div>
        <div className='flex max-w-[1280px] lg:mx-auto lg:container lg:flex-row flex-col h-full w-full justify-center items-center relative'>
          <div className='flex flex-col lg:space-y-0 lg:flex-row justify-center text-center lg:text-left lg:justify-between w-full md:w-[736px] lg:w-full h-full py-[100px]'>
              <div className='flex w-full justify-center h-full z-20 lg:justify-between lg:items-center text-base-white'>
                  <div className='flex md:w-[70%] lg:w-[65%] flex-col'>
                      <p className={`${montserratSemibold.className} leading-6`}>{sectionContent.title}</p>
                      <p className={`${montserratBold.className} leading-[43.2px] text-4xl py-3`}>{sectionContent.main}</p>
                      <p className={`${montserratNormal.className} leading-7`}>{sectionContent.description}</p>
                  </div>
              </div>
              <form className='flex w-full mt-16 lg:mt-0 relative z-20 h-fit' id='form_contact_us_web'>
                  <div className={`${montserratNormal.className} grid w-full grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2`}>
                      <div className='row-span-1 w-full'>
                        <InputText
                          onChange={onChange}
                          type={"text"}
                          name={"name"}
                          id={"name"}
                          value={form.name}
                          placeholder={(labelForm.find((v => v.type == "name"))).text}
                        />
                      </div>
                      <div className={`rounded-[8px] hidden md:grid`}>
                        {
                          isOther ? (
                            <div className='flex relative w-full'>
                                <input
                                  type="text"
                                  name={"industry"}
                                  id={"industry"} 
                                  onChange={onChange}
                                  placeholder={(labelForm.find((v => v.type == "custom_industry"))).text}
                                  value={form.industry}
                                  className='w-full rounded-[8px] border border-gray-cool-300 bg-base-white flex items-center px-[14px] py-2'/>
                                <div onClick={clearFormIndustry} className='absolute rounded-[8px] flex items-center h-full right-0 px-[14px] py-2'>
                                  <XMarkIcon width={20} height={20}/>
                                </div>
                            </div>
                          ) : (
                            <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: isError.industry
                                      ? "#F04438"
                                      : "#d1d5db",
                                  }),
                                }}
                                onChange={selectIndustry}
                                value={selectedIndustry}
                                classNamePrefix="select"
                                placeholder={(labelForm.find((v => v.type == "industry"))).text}
                                isClearable={false}
                                isSearchable={true}
                                name="industry"
                                id={"industry"}
                                options={dataIndustry}
                              />
                          )
                        }
                      </div>
                      {/* <InputText
                          type={"text"}
                          name={"full_name"}
                          placeholder={"Name"}
                      /> */}
                      <PhoneNumberInput 
                          onChange={phoneChange}
                          value={form.phone}
                          placeholder={"Phone Number"}
                          country={"id"}
                          name={"phone"}
                          id={"phone"}
                          error={false}
                          messageError={""}
                    />
                    <div className='grid w-full md:hidden'>
                      <InputText
                          onChange={onChange}
                          type={"email"}
                          value={form.email}
                          name={"email"}
                          id={"email"}
                          placeholder={(labelForm.find((v => v.type == "email"))).text}
                      />
                    </div>
                    <div className={`rounded-[8px] grid md:hidden`}>
                        {
                          isOther ? (
                            <div className='flex relative w-full'>
                                <input
                                  type="text"
                                  name={"industry"}
                                  id={"industry"} 
                                  onChange={onChange}
                                  placeholder={(labelForm.find((v => v.type == "custom_industry"))).text}
                                  value={form.industry}
                                  className='w-full rounded-[8px] border border-gray-cool-300 bg-base-white flex items-center px-[14px] py-2'/>
                                <div onClick={clearFormIndustry} className='absolute rounded-[8px] flex items-center h-full right-0 px-[14px] py-2'>
                                  <XMarkIcon width={20} height={20}/>
                                </div>
                            </div>
                          ) : (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: isError.industry
                                    ? "#F04438"
                                    : "#d1d5db",
                                }),
                              }}
                              onChange={selectIndustry}
                            //   value={selectedIndustry}
                              classNamePrefix="select"
                              placeholder={(labelForm.find((v => v.type == "industry"))).text}
                              isClearable={false}
                              isSearchable={true}
                              name="industry"
                              id={"industry"}
                              options={dataIndustry}
                            />
                          )
                        }
                      </div>
                    <div className='grid md:hidden row-span-2 h-full'>
                        <Textarea
                            onChange={onChange}
                            name={"message"}
                            id={"message"}
                            value={form.message}
                            placeholder={(labelForm.find((v => v.type == "message"))).text}
                        />
                    </div>
                    <div className='hidden md:grid row-span-2 h-full'>
                        <Textarea
                            onChange={onChange}
                            name={"message"}
                            id={"message"}
                            value={form.message}
                            placeholder={(labelForm.find((v => v.type == "message"))).text}
                        />
                    </div>
                    <div className='hidden w-full md:grid'>
                      <InputText
                          type={"email"}
                          name={"email"}
                          id={"email"}
                          value={form.email}
                          onChange={onChange}
                          placeholder={(labelForm.find((v => v.type == "email"))).text}
                      />
                      {
                        isError.email && (
                          <div className={`text-semantic-negative-600 text-left mt-2 ${montserratSemibold.className}`}>{messageError.email}</div>
                        )
                      }
                    </div>
                    <div className='flex w-full space-x-4'>
                      <Button onClick={handleSubmit} id="submit-form" disabled={form.name && emailRegex.test(form.email) && form.industry && form.phone && !isLoading ? false : true } variant='outline' className={`w-fit ${form.name && emailRegex.test(form.email) && form.industry && form.phone && !isLoading ? "hover:bg-base-white hover:text-base-black border-base-white text-base-white" : "hover:cursor-not-allowed border-base-white text-base-white "} ${montserratNormal.className} h-fit`}>
                          {
                            isLoading ? (
                              <svg xmlns="http://www.w3.org/2000/svg" className='animate-spin' width="21" height="20" viewBox="0 0 21 20" fill="none">
                                <path d="M18.9409 10C19.802 10 20.5124 9.29783 20.3787 8.44721C20.2563 7.66837 20.0419 6.90492 19.7388 6.17317C19.2362 4.95991 18.4997 3.85752 17.5711 2.92893C16.6425 2.00035 15.5401 1.26375 14.3268 0.761205C13.5951 0.4581 12.8316 0.243716 12.0528 0.121293C11.2022 -0.0124119 10.5 0.698026 10.5 1.55908V1.55908C10.5 2.42014 11.2067 3.10061 12.0458 3.29402C12.4165 3.37948 12.7804 3.49574 13.1336 3.64202C13.9685 3.98786 14.7272 4.49477 15.3662 5.13381C16.0052 5.77285 16.5121 6.5315 16.858 7.36644C17.0043 7.71959 17.1205 8.08347 17.206 8.45421C17.3994 9.29326 18.0799 10 18.9409 10V10Z" fill="white"/>
                              </svg>
                            ) : (
                              <>
                                {(labelForm.find((v => v.type == "button_submit"))).text}
                              </>
                            )
                          }
                          {/* <div class=" h-20 w-20 animate-spin rounded-full border-[20px] border-t-blue-primary-600" /> */}
                      </Button>
                      {
                        isSuccess && (
                          <div className='flex flex-col'>
                            <p className={`flex text-base-white ${montserratSemibold.className} items-center`}>
                              Berhasil terkirim! 
                              <span className='px-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 6.5C0.5 2.91015 3.41015 0 7 0C10.5899 0 13.5 2.91015 13.5 6.5C13.5 10.0899 10.5899 13 7 13C3.41015 13 0.5 10.0899 0.5 6.5ZM9.40687 5.29062C9.56737 5.06591 9.51533 4.75364 9.29062 4.59313C9.06591 4.43263 8.75364 4.48467 8.59313 4.70938L6.4362 7.72909L5.35355 6.64645C5.15829 6.45118 4.84171 6.45118 4.64645 6.64645C4.45118 6.84171 4.45118 7.15829 4.64645 7.35355L6.14645 8.85355C6.25037 8.95748 6.39476 9.01042 6.54124 8.9983C6.68772 8.98617 6.82144 8.91022 6.90687 8.79062L9.40687 5.29062Z" fill="#FCFCFD"/>
                                </svg>
                              </span>
                            </p>
                            <p className={`flex text-base-white ${montserratNormal.className} items-center`}>Kami akan menghubungi Kamu dalam 24 jam.</p>
                          </div>
                        )
                      }
                    </div>
                </div>
              </form>
          </div>
        </div>
        
    </div>
  )
}
