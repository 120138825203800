import homeSection from '@/assets/homesection/home-1.png'
import homeSectionTablet from '@/assets/homesection/home-tablet.png'
import homeSectionMobile from '@/assets/homesection/home-mobile.png'

export const home = {
    "section" : "home",
    "imageDesktop" : homeSection,
    "imageMobile" : homeSectionMobile,
    "imageTablet" : homeSectionTablet,
    "text" : {
        "tiny_title": "Asisten Virtual Keuangan Bisnis",
        "main_title": "Terima Beres Laporan Keuangan",
        "description": "Dari mulai pembukuan sampai perpajakan, Ledgerowl bantu urus dan kelola finansial bisnis kamu. Semuanya dengan mudah, murah, dan cepat!",
        "button": {
            "first": "Harga",
            "second": "Hubungi Kami"
        },
        "in_pict": {
            "first_card" : {
                "title" : "Net Profit",
                "description" : "Rp 972,000,000.00",
                "detail_description" : "dari bulan lalu",
                "badge_text": "60.7%"
            },
            "second_card" : {
                "main_title" : "Faktur Pajak",
                "second_title" : "Tipe Pajak",
                "third_title" : "Jumlah",
                "second_description": "Pph 21",
                "third_description" : "Rp 900,000"
            }
        }
    }
}