export const servicesContent = {
    title: "Layanan Bisnis Ledgerowl",
    description: "Kami hadir dengan layanan bisnis yang dapat Kamu pilih dan personalisasi sesuai kebutuhan."
}

export const servicesTabs = [
    {
        type: "bookeeping",
        url: "/bookkeeping",
        name: "Pembukuan",
        title: "Simplifikasi Proses Pembukuan dengan Sistem Terintegrasi Otomatis",
        description: "Memastikan Transparansi dan Akurasi dalam Pembukuan untuk Mendukung Keputusan Bisnis yang Tepat",
        list_detail: [
            {
                type: "service-bookeeping",
                name: "Jasa Pembukuan",
            },
            {
                type: "service-consultation",
                name: "Jasa Konsultasi",
            },
            {
                type: "service-report",
                name: "Jasa Pembuatan Laporan Keuangan Bulanan",
            },
            {
                type: "reconciliation",
                name: "Rekonsiliasi Bank dan Hutang Piutang",
            },
        ]
    },
    {
        type: "tax",
        name: "Perpajakan",
        url: "/tax",
        title: "Optimalkan Perhitungan Perpajakan yang Efektif dan Terpercaya",
        description: "Memastikan Kepatuhan Pajak Tepat Waktu dengan Layanan Perpajakan yang Terpercaya dan Komprehensif",
        list_detail: [
            {
                type: "administration-tax",
                name: "Jasa Administrasi Perpajakan",
            },
            {
                type: "service-report-spt-self",
                name: "Jasa Perhitungan & Pelaporan SPT Tahunan PPh Orang Pribadi",
            },
            {
                type: "service-report-spt-company",
                name: "Jasa Perhitungan & Pelaporan SPT Tahunan PPh Badan",
            },
            {
                type: "service-counting-report-spt",
                name: "Jasa Perhitungan & Pelaporan SPT PPN",
            },
        ]
    },
    {
        type: "reconciliatio",
        name: "Rekonsiliasi Otomatis",
        url: "/reconciliation-automation",
        title: "Automasi Proses Rekonsiliasi dengan Platform AI Terpadu Kami",
        description: "Optimalkan aliran keuangan bisnismu dengan mudah. Hubungkan ERP, rekening bank, dan gateway pembayaran dalam satu platform. Selesaikan proses rekonsiliasi dalam hitungan menit.",
        list_detail: []
    },
    {
        type: "operation-finance",
        name: "Operasi Finansial",
        url: "/finance-operation",
        title: "Bantuan Tim Ahli Finansial untuk Bisnis Anda",
        description: "Mengelola Arus Kas, Invoice, dan Pengeluaran dengan Lebih Mudah untuk Mencegah Keterlambatan Pembayaran",
        list_detail: [
            {
                type: "credit-management",
                name: "Manajemen Piutang",
            },
            {
                type: "debit-management",
                name: "Manajemen Hutang",
            },
            {
                type: "salary-management",
                name: "Manajemen Penggajian",
            },
            {
                type: "tax-fraktur",
                name: "Pembuatan Faktur Pajak",
            },
        ]
    },
    {
        type: "legalitation",
        name: "Legalitas Usaha",
        url: "/legal",
        title: "Raih Kepercayaan Pelanggan dengan Legalitas Usaha",
        description: "Panduan lengkap dan mudah untuk urusan legalitas bisnis Kamu dengan bantuan tim profesional yang berpengalaman",
        list_detail: [
            {
                type: "service-legalitation-company",
                name: "Jasa Pengurusan Pendirian Perusahaan (Perorangan)",
            },
            {
                type: "service-legalitation-organization",
                name: "Jasa Pengurusan Pendirian Perusahaan PT/CV/Yayasan",
            },
            {
                type: "service-change-akta",
                name: "Jasa Perubahan Akta Perusahaan",
            },
            {
                type: "service-nib",
                name: "Jasa Penerbitan KBLI dan NIB Baru",
            },
        ]
    },
]