export const testimoniContent = {
    mini_title : "Testimoni",
    title: "Kata Mereka tentang Ledgerowl",
    description: "Cerita klien tentang pengalaman mereka dengan kami. Kisah nyata, hasil nyata."
}

export const testimoniList = [
    {
        active: true,
        pic_name: "Khaled",
        pic_position: "Business Owner",
        name_client: "Tennis Launcher",
        type_business: "Education & Training",
        title_commentary: "Dengan Ledgerowl, bisnis makin growing. Atur finansial tanpa overthinking.",
        commentary : "",
        commentary_2: "",
        type_services : [
            {
                type:"bookeeping",
                name: "Pembukuan"
            }
        ]
    },
    {
        active: false,
        pic_name: "Abirama",
        pic_position: "CEO",
        name_client: "Petiq Bistro",
        type_business: "FnB Business",
        title_commentary: "",
        commentary : "Pengalaman kami selama ini menggunakan Ledgerowl sangat memuaskan, kami selalu difasilitasi dengan baik, detail dan lengkap. Ledgerowl juga mampu menyesuaikan kebutuhan bisnis kami, dan mengsupport kami dalam pengembangan bisnis.",
        commentary_2: "",
        type_services : [
            {
                type:"bookeeping",
                name: "Pembukuan"
            },
            {
                type:"tax",
                name: "Perpajakan"
            }
        ]
    },
    {
        active: false,
        pic_name: "Henrietta Danubrata & Cindy Suryadinata",
        pic_position: "Director",
        name_client: "The Blanc Space",
        type_business: "Design Studio",
        title_commentary: "Serahkan keuangan ke Ledgerowl, kami hanya fokus berbisnis.",
        commentary : "Ledgerowl sangat membantu dalam mengelola bisnis desain kecil kami. Mereka ahli dalam pajak dan pengisian pajak, selalu siap menjawab pertanyaan kami. Antarmuka dan pengalaman pengguna mereka terus berkembang sesuai kebutuhan kami.",
        commentary_2: "Sebagai studio kecil, kami bisa fokus pada proyek kreatif klien karena Ledgerowl mengurus banyak hal untuk kami. Ini membuat kami lebih efisien dalam mengelola waktu. Pendekatan mereka yang menyediakan semua layanan dalam satu tempat sangat berharga bagi kami.",
        type_services : [
            {
                type:"bookeeping",
                name: "Pembukuan"
            },
            {
                type:"tax",
                name: "Perpajakan"
            }
        ]
    },
    {
        active: false,
        pic_name: "Kent Tjai",
        pic_position: "Business Owner",
        name_client: "Menya Kenji",
        type_business: "FnB Business",
        title_commentary: "",
        commentary : "Kami sangat puas dengan kinerja dan service dari Ledgerowl. Sangat memuaskan dan very accommodating.",
        commentary_2: "Ledgerowl sangat membantu sekali, laporan keuangan bisnis kami menjadi sangat rapih. semoga kami bisa langgeng dan seterusnya dengan Ledgerowl.",
        type_services : [
            {
                type:"bookeeping",
                name: "Pembukuan"
            },
        ]
    },
    {
        active: false,
        pic_name: "Rishanda Singgih",
        pic_position: "Business Owner",
        name_client: "The Rising",
        type_business: "Music Producer",
        title_commentary: "Dengan Ledgerowl bisa fokus bikin karya dan let them do the boring things.",
        commentary : "",
        commentary_2: "",
        type_services : [
            {
                type:"bookeeping",
                name: "Pembukuan"
            },
            {
                type:"tax",
                name: "Perpajakan"
            },
            {
                type:"legalitation",
                name: "Legalitas Usaha"
            }
        ]
    },
]