'use client'
import React from 'react'
import Image from 'next/image'
import { home } from '@/helpers/content/id/home'
import { montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import Button from '@/components/Button/Button'
import MiniCardParent from './MiniCardParent/MiniCardParent'
function HomeSection({homeRef,moveToSection, home, isThreeCard = false,widthContainer= "max-w-[640px]", cardStackThree, miniCard, children, widthDescription="lg:w-full"}) {
 
  let mainText = home.text

  const redirectLink = (link) => {
    window.open(link,"_blank")
  }

  return (
    <div ref={homeRef} className='flex lg:flex-row flex-col w-full h-full max-w-[1280px] lg:container lg:mx-auto relative'>
        <div className='flex lg:flex-row flex-col mx-auto w-full justify-between items-center h-full lg:container relative'>
            <div className={`flex px-4 py-6 md:py-6 lg:py-0 flex-col justify-center lg:px-8 xl:px-0 ${widthContainer} h-full`}>
                <p className={`${montserratSemibold.className} text-blue-primary-500`}>{mainText.tiny_title}</p>
                <h1 className={`${montserratSemibold.className} py-3 lg:py-6 md:py-6 text-gray-cool-700 -tracking-[1.2px] self-stretch md:leading-[95%] lg:leading-[95%] leading-[44px] text-[36px] lg:text-[60px] md:text-[60px]`}>{mainText.main_title}</h1>
                <h4 className={`${montserratNormal.className} w-full ${widthDescription} text-xl leading-[30px] tracking-tight text-gray-cool-700`}>{mainText.description}</h4>
                <div className='flex w-full space-x-3 pt-6 lg:pt-12 md:py-12'>
                    {
                        mainText.button.second ? (
                            <>
                                <Button onClick={() => moveToSection("pricingRef")} variant='solid' className={`bg-blue-primary-500 hover:cursor-pointer hover:bg-blue-primary-600 text-base-white ${montserratSemibold.className}`}>{mainText.button.first}</Button>
                                <Button onClick={() => moveToSection("contact")} variant='solid' className={`text-gray-cool-700 hover:bg-gray-cool-100 hover:cursor-pointer ${montserratSemibold.className}`}>{mainText.button.second}</Button>
                            </>
                        ) : (
                            <Button onClick={() => redirectLink(mainText.button.link_second)} variant='solid' className={`bg-blue-primary-500 hover:cursor-pointer w-fit hover:bg-blue-primary-600 text-base-white ${montserratSemibold.className}`}>{mainText.button.first}<Image className="w-5 ml-3" src={mainText.button.image} /></Button>
                        )
                    }
                </div>
            </div>
            {children}
        </div>
    </div>
  )
}

export default HomeSection