import { montserratBold, montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import Tabs from '@/components/Tabs/Tabs'
import { testimoniContent, testimoniList } from '@/helpers/tabs/testimoni'
import React, { useEffect, useState } from 'react'
import tennis from "@/assets/testimonial/tennis.png"
import petiq from "@/assets/testimonial/petiq.png"
import blancSpace from "@/assets/testimonial/blancspace.png"
import menya from "@/assets/testimonial/menya.png"
import rising from "@/assets/testimonial/rising.png"
import Image from 'next/image'
import quoteUp from "@/assets/testimonial/quoteup.svg"
import quoteDown from "@/assets/testimonial/quotedown.svg"
import checklist from "@/assets/testimonial/checklist.svg"
import arrowLeft from "@/assets/testimonial/arrow-left.svg"


export default function Testimoni() {

    const [testimoni, settestimoni] = useState(testimoniList)
    const [imageTestimoni, setimageTestimoni] = useState([tennis,petiq,blancSpace,menya,rising])
    const [nextTab, setnextTab] = useState(0)
    const [elapsedTime, setElapsedTime] = useState(0);
    const [height, setHeight] = useState("h-[400px] md:h-[200px]")
    const [isReadMore, setisReadMore] = useState(false)
    const [isLessRead, setisLessRead] = useState(false)
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
          // Increment progress by 20% every second until it reaches 100%
          setProgress(prevProgress => prevProgress + 1);
        },100);
    
        // Clear the interval after 5 seconds
    
        return () => clearInterval(interval);
    },[]);

    useEffect(() => {
      if (progress >= 100) {
        const newTesti = testimoni
        newTesti[nextTab].progress = progress
        settestimoni(newTesti)
        setnextTab(prev => prev >= parseInt(testimoni.length-1)? 0 : prev + 1)
        setProgress(0)
      }
    }, [progress])

    useEffect(() => {
        setisLessRead(false)
        setHeight("h-[400px] md:h-[200px]")
        if (nextTab <= 0) {
            const newTesti = testimoni.map((val,i) => {
                if (i != nextTab) {
                    return {...val,progress:0}
                }else{
                    return {...val}
                }
            })
            settestimoni(newTesti)
        }
        let lengthText = 0
        lengthText = (testimoni[nextTab].title_commentary + testimoni[nextTab].commentary + testimoni[nextTab].commentary_2).length
        
        // alert(lengthText)
        if (lengthText > 450) {
            setisReadMore(true)
        }else{
            setisReadMore(false)
        }
    //   console.log("NEXT TAB",nextTab)
    //   console.log("Length Testimoni", testimoni.length)
    }, [nextTab])
    
    
    const handleChangeTab = (index) => {
        let newTesti = testimoni
        if (index == parseInt(testimoni.length - 1)) {
            for (let i = 0; i < testimoni.length; i++) {
                newTesti[i] = {...testimoni[i],progress:100}
            }
        }else{
            if (index < testimoni.length) {
                console.log("A")
                for (let i = index; i < testimoni.length; i++) {
                    console.log(i)
                    newTesti[i] = {...testimoni[i],progress:0}
                }
                for (let i = index; i > -1; i--) {
                    console.log(i)
                    newTesti[i] = {...testimoni[i],progress:100}
                }
            }
            else{
                for (let i = (testimoni.length - 1); i > index; i--) {
                    console.log("I",i)
                    newTesti[i] = {...testimoni[i],progress:0}
                }
            }
        }
        console.log(newTesti)
        setnextTab(index)
        setProgress(0)
        settestimoni(newTesti)
    }

    const handleReadMore = () => {
        setHeight("h-full")
        setisReadMore(false)
        setisLessRead(true)
    }

    const handleLessRead = () => {
        setHeight("h-[400px] md:h-[200px]")
        setisReadMore(true)
        setisLessRead(false)
    }
    
    

  return (
    <div className='flex flex-col px-4 py-16 lg:py-16 lg:pb-0 lg:px-8 relative lg:mx-auto lg:container w-full h-full max-w-[1280px]'>
        <div className='w-full h-fit justify-center items-center flex flex-col'>
            <div className='w-full md:w-[600px] pb-16 h-fit justify-center items-center flex flex-col'>
                <p className={`${montserratSemibold.className} text-base text-blue-primary-500`}>{testimoniContent.mini_title}</p>
                <h1 className={`${montserratBold.className} text-center py-4 leading-10 leading-[44px] text-[36px] text-gray-cool-700`}>{testimoniContent.title}</h1>
                <p className={`${montserratNormal.className} text-[20px] text-center text-gray-cool-500`}>{testimoniContent.description}</p>
            </div>
        </div>
        <Tabs>
            <Tabs.Group className={"hidden lg:flex h-full w-full"}>
                <div className='flex w-full space-x-4'>
                    {
                        testimoni.map((val, index) => (
                            <Tabs.Tab onClick={() => handleChangeTab(index)} key={index} className={"flex hover:cursor-pointer relative w-full flex-col"}>
                                <div className='h-1 absolute w-full bg-gray-cool-200'/>
                                <div className='h-1 absolute bg-gray-cool-400' style={{width : `${progress > 0 && index == nextTab ? progress : val.progress != undefined ? val.progress : 0}%`, transition: 'width 0.1s ease-in-out'}}/>
                                <div className='flex pt-4 w-full flex-col'>
                                    <p className={`${montserratSemibold.className} text-[20px] ${nextTab == index ? "text-gray-cool-700" : "text-gray-cool-400"}`}>{val.name_client}</p>
                                    <p className={`${montserratNormal.className} ${nextTab == index ? "text-gray-cool-500" : "text-gray-cool-400"}`}>{val.type_business}</p>
                                </div>
                            </Tabs.Tab>
                        ))
                    }
                </div>
                <Tabs.Content className={"flex py-12 w-full h-full"}>
                    {
                        testimoni.map((val, index) => (
                            <>
                                {
                                    index == nextTab && (
                                        <div className='flex w-full relative h-full'>
                                            <div className='flex pr-5 relative justify-between flex-col w-1/4 h-[400px]'>
                                                <div className='flex h-full flex-col'>
                                                    <div className='w-[60px]'>
                                                        <Image src={imageTestimoni[index]} />
                                                    </div>
                                                    <div className={`${montserratSemibold.className} text-[20px] text-gray-cool-700`}>
                                                        {val.pic_name}
                                                    </div>
                                                    <div className={`${montserratNormal.className} text-base text-gray-cool-500`}>
                                                        {val.pic_position}
                                                    </div>
                                                </div>
                                                <div className='flex relative justify-end flex-col'>
                                                    <p className={`${montserratSemibold.className} pb-2 text-gray-cool-700`}>Layanan yang digunakan</p>
                                                    <ul className='flex space-y-2 flex-col'>
                                                        {
                                                            val.type_services.map((service,i) => (
                                                                <li className='w-fit h-fit flex'>
                                                                    <div className='w-auto h-auto justify-center flex items-center pr-[12px]'>
                                                                        <div className='flex rounded-full justify-center items-center bg-gray-cool-100 p-[6px]'>
                                                                            <Image src={checklist} />
                                                                        </div>
                                                                    </div>
                                                                    <div className={`${montserratNormal.className} text-gray-cool-500`}>{service.name}</div>
                                                                </li>
                                                            ))
                                                        }
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='w-full bg-gray-cool-50 p-8 h-full flex flex-col justify-center items-center relative'>
                                                <div className='flex top-5 left-5 absolute z-0 w-[100px]'>
                                                    <Image src={quoteDown}/>
                                                </div>
                                                <div className='flex bottom-5 right-5 absolute z-0 w-[100px]'>
                                                    <Image src={quoteUp}/>
                                                </div>
                                                <div className={`h-[400px] flex-col w-full flex z-10 justify-center items-center ${val.title_commentary && val.commentary ? "" : ""}`}>
                                                    <p className={`${montserratSemibold.className} w-full text-left text-[28px] text-gray-cool-700`}>{val.title_commentary}</p>
                                                    <p className={`${montserratNormal.className} w-full text-left text-gray-cool-500 ${val.title_commentary && val.commentary ? "py-4" : ""}`}>{val.commentary}</p>
                                                    <p className={`${montserratNormal.className} w-full text-left text-gray-cool-500`}>{val.commentary_2}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        ))
                    }
                </Tabs.Content>
                <div className='border-t-[1px] w-full flex justify-center items-center border-gray-cool-200'>
                    <div className='w-full space-x-3 pt-8 justify-center items-center flex'>
                        <div onClick={() => handleChangeTab(nextTab <= 0 ? 0 : nextTab-1)} className='flex p-2 justify-center hover:cursor-pointer hover:bg-gray-cool-50 w-fit h-fit border border-gray-cool-300 rounded-full'>
                            <Image src={arrowLeft} />
                        </div>
                        <div onClick={() => handleChangeTab(nextTab >= (testimoni.length - 1) ? 0 : nextTab+1)} className='flex p-2 rotate-180 justify-center hover:cursor-pointer hover:bg-gray-cool-50 w-fit h-fit border border-gray-cool-300 rounded-full'>
                            <Image src={arrowLeft} />
                        </div>
                    </div>
                </div>
            </Tabs.Group>
            <Tabs.Group className={"flex flex-col w-full h-full md:justify-center md:items-center lg:hidden"}>
                <div className='flex w-full  md:w-[600px] flex-col relative'>
                    <div className='h-1 absolute w-full bg-gray-cool-200'/>
                    <div className='h-1 absolute bg-gray-cool-400' style={{width : `${progress > 0 ? progress : testimoni[nextTab].progress != undefined ? testimoni[nextTab].progress : 0}%`, transition: 'width 0.1s ease-in-out'}}/>
                </div>
                <Tabs.Tab className='flex w-full py-4 pb-8 md:w-[600px] justify-between items-center'>
                     <div className='flex flex-col'>
                        <div className={`${montserratSemibold.className} text-[20px] text-gray-cool-700`}>
                            {testimoni[nextTab].name_client}
                        </div>
                        <div className={`${montserratNormal.className} text-base text-gray-cool-500`}>
                            {testimoni[nextTab].type_business}
                        </div>
                     </div>
                    <div className='space-x-3 justify-end flex'>
                        <div onClick={() => handleChangeTab(nextTab <= 0 ? 0 : nextTab-1)} className={`${nextTab == 0 ? "hidden" : "flex"} p-2 justify-center hover:cursor-pointer hover:bg-gray-cool-50 w-fit h-fit border border-gray-cool-300 rounded-full`}>
                            <Image src={arrowLeft} />
                        </div>
                        <div onClick={() => handleChangeTab(nextTab >= (testimoni.length - 1) ? 0 : nextTab+1)} className={`flex p-2 rotate-180 justify-center hover:cursor-pointer hover:bg-gray-cool-50 w-fit h-fit border border-gray-cool-300 rounded-full`}>
                            <Image src={arrowLeft} />
                        </div>
                    </div>
                </Tabs.Tab>
                <Tabs.Content className={"flex  md:w-[600px] md:justify-center md:items-center flex-col w-full"}>
                    <div className='w-full bg-gray-cool-50 h-full flex flex-col justify-center items-center relative'>
                        <div className='flex top-5 left-5 absolute z-0 w-[100px]'>
                            <Image src={quoteDown}/>
                        </div>
                        <div className='flex bottom-5 right-5 absolute z-0 w-[100px]'>
                            <Image src={quoteUp}/>
                        </div>
                        <div className={`${height} w-full justify-center items-center relative overflow-hidden flex-col flex z-10`}>
                            <div className={`${testimoni[nextTab].title_commentary && testimoni[nextTab].commentary && isReadMore ? "top-20 md:top-12" : ""} flex px-4 w-full h-full relative justify-center flex-col`}>
                                <p className={`${montserratSemibold.className} text-[20px] ${testimoni[nextTab].title_commentary && testimoni[nextTab].commentary ? "pt-8" : ""} text-gray-cool-700`}>{testimoni[nextTab].title_commentary}</p>
                                <p className={`${montserratNormal.className} text-gray-cool-500 ${testimoni[nextTab].title_commentary && testimoni[nextTab].commentary ? "pt-1" : ""}`}>{testimoni[nextTab].commentary}</p>
                                <p className={`${montserratNormal.className} text-gray-cool-500 pt-1`}>{testimoni[nextTab].commentary_2}</p>
                            </div>
                            {
                                isReadMore ? (
                                    <div className={`w-full h-[4rem] flex absolute z-20 justify-center items-center bg-gray-cool-50 opacity-90 bottom-0`}>
                                        <div onClick={handleReadMore} className={`${montserratSemibold.className} text-sm text-blue-primary-500 w-full flex absolute justify-center items-center bottom-5 z-30`}>Read More</div>
                                    </div>
                                ) : ""
                            }
                            {
                                isLessRead && (
                                    <div onClick={handleLessRead} className={`${montserratSemibold.className} flex w-full mt-8 pb-4 bottom-0 text-sm text-blue-primary-500 relative justify-center items-center`}>Read Less</div>
                                )
                            }
                        </div>
                    </div>
                    <div className='flex py-8 flex-col w-full'>
                        <div className='w-[60px]'>
                            <Image src={imageTestimoni[nextTab]} />
                        </div>
                        <div className={`${montserratSemibold.className} pt-4 text-[20px] text-gray-cool-700`}>
                            {testimoni[nextTab].pic_name}
                        </div>
                        <div className={`${montserratNormal.className} text-base text-gray-cool-500`}>
                            {testimoni[nextTab].pic_position}
                        </div>
                        <div className='flex pt-10 relative justify-end flex-col'>
                            <p className={`${montserratSemibold.className} pb-2 text-gray-cool-700`}>Layanan yang digunakan</p>
                            <ul className='flex space-y-2 flex-col'>
                                {
                                    testimoni[nextTab].type_services.map((service,i) => (
                                        <li className='w-fit h-fit flex'>
                                            <div className='w-auto h-auto justify-center flex items-center pr-[12px]'>
                                                <div className='flex rounded-full justify-center items-center bg-gray-cool-100 p-[6px]'>
                                                    <Image src={checklist} />
                                                </div>
                                            </div>
                                            <div className={`${montserratNormal.className} text-gray-cool-500`}>{service.name}</div>
                                        </li>
                                    ))
                                }
                                
                            </ul>
                        </div>
                    </div>
                </Tabs.Content>
            </Tabs.Group>
        </Tabs>
    </div>
  )
}
