import { montserratBold, montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat';
import Mosanry from '@/components/Mosanry/Mosanry';
import { clientContent } from '@/helpers/content/id/client';
import React from 'react'

const ClientList = ({clientRef}) => {
  return (
    <div ref={clientRef} className='relative flex pt-[128px] md:pt-[128px] lg:pt-16 justify-center w-full items-center lg:px-8 h-full xl:mx-auto xl:container p-4 '>
        <div className='flex justify-center items-center flex-wrap '>
            <div className='w-full flex-col flex justify-center items-center text-center'>
                <div className={`${montserratSemibold.className} text-blue-primary-500`}>{clientContent.tiny_title}</div>
                <div className={`${montserratBold.className} py-3 text-gray-cool-700 leading-[44px] text-[36px]`}>
                    {clientContent.title}
                </div>
                <div className={`${montserratNormal.className} text-[20px] text-gray-cool-500 w-full md:w-[35rem] lg:w-[40rem]`}>
                    {clientContent.description}
                </div>
            </div>
            <div className='flex w-full lg:w-full justify-center items-center relative lg:rounded-full overflow-hidden flex-wrap'>
                {/* <div className='clip-ellipse w-[328px] md:w-[600px] absolute h-full'/> */}
                <Mosanry />
            </div>
        </div>
    </div>
  )
}

export default ClientList;
